<template>
  <div>
    <b-modal id="showClientData" size="lg" :title="$t('veterinary.inactive_clinic')" style="direction: rtl" centered hide-footer hide-header-close>
      <b-row>
        <b-col md="6" class="mb-3">
          <span class="title">اسم العيادة</span>
          <span class="res font-weight-bold">{{ clinicInfo.clinic_name }}</span>
        </b-col>
        <b-col md="6" class="mb-3">
          <span class="title">البريد الالكترونى</span>
          <span class="res font-weight-bold">{{ clinicInfo.email }}</span>
        </b-col>
        <b-col md="6" class="mb-3">
          <span class="title">رقم الهاتف</span>
          <span class="res font-weight-bold">{{ clinicInfo.primary_phone_number }}</span>
        </b-col>
        <b-col md="6" class="mb-3">
          <span class="title">رقم الهاتف الثانوى</span>
          <span class="res font-weight-bold">{{ clinicInfo.secondary_phone_number }}</span>
        </b-col>
        <b-col sm="12" class="d-flex justify-content-center mt-4">
          <b-button variant="primary" class="vete-save-btn m-auto" v-if="!loading" @click="acceptClinic(clinicInfo.id)"> قبول </b-button>
          <b-button variant="primary" class="vete-save-btn m-auto" v-else> <spinner-loading :text="'يتم التحميل'"></spinner-loading> </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <dashboard-page-title  :showAddBtn="false" :showFilter="false" :showSearch="false" :showExportation="false">العيادات الغير مفعلة</dashboard-page-title>
    <main-table :fields="fields" list_url="admin/clinics" :reloadTable="reloadTable" :additional-url="`&status=processing`" :baseURL="VUE_APP_VETE_LINK"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { VUE_APP_VETE_LINK } from '@/config/constant'
import api from '../services/index'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      VUE_APP_VETE_LINK,
      loading: false,
      clinicInfo: '',
      reloadTable: false,
      fields: [
        { label: 'Id', key: 'id', class: 'text-right', sortable: true },
        { label: 'الاسم', key: 'clinic_name', class: 'text-right' },
        { label: 'البريد الإلكترونى', key: 'email', class: 'text-right' },
        { label: 'رقم التليفون', key: 'primary_phone_number', class: 'text-right' },
        { label: 'تاريخ الطلب', key: 'created_at', class: 'text-right' },
        {
          label: 'التحكم',
          key: 'actions',
          type: 'actions',
          class: 'text-right',
          actions: [
            {
              icon: 'las la-eye',
              text: 'show',
              actionParams: 'fullObj',
              actionName: 'showData'
            }
          ]
        }
      ]
    }
  },
  methods: {
    showData (data) {
      this.clinicInfo = data
      this.$bvModal.show('showClientData')
    },
    acceptClinic (id) {
      this.loading = true
      api.acceptClinic(id).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('showClientData')
        this.reloadTable = true
      }).finally(() => {
        this.loading = false
      })
    }
  },
  created () {
    this.$root.$on('showData', this.showData)
  }
}
</script>

<style lang="scss" scoped>
.title {
  min-width: 150px;
  display: inline-block;
}
.title, .res {
  color: #333
}
</style>
